<template>
  <a-modal
    :visible="value"
    title="将经销商加入已有分组"
    :mask-closable="false"
    @cancel="handleCancel"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ flex: '0 0 140px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 140px)'}"
    >
      <a-form-model-item class="mb-0" label="已选经销商" prop="dealerIds">
        {{ form.dealerIds.length }}
      </a-form-model-item>
      <a-form-model-item label="选择我创建的分组" prop="dealerGroupIds">
        <a-checkbox-group
          v-model="form.dealerGroupIds"
          @change="handleDealerGroupChange"
        >
          <div class="group-wrap" v-for="item in groupList" :key="item.groupId">
            <a-checkbox :value="item.groupId" />
            <div>
              <span>{{ item.groupName }}</span>
              <span v-if="item.syncAuthorCount">
                ({{ item.isCommon === 1 ? '公共分组' : '私有分组' }}，同步{{ item.syncAuthorCount }}个播主分组)
              </span>
              <span v-else>
                ({{ item.isCommon === 1 ? '公共分组' : '私有分组' }})
              </span>
            </div>
          </div>
        </a-checkbox-group>
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <div class="btn-wrap">
        <div class="btn-wrap__left">
           <a-button class="btn-link" type="link" @click="handleAddGroup">
            新建分组
          </a-button>
        </div>
        <div class="btn-wrap__right">
          <a-button @click="handleCancel">
            取消
          </a-button>
          <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">
            确定
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import deepClone from '@/utils/deepClone'

const form = {
  dealerIds: [],
  dealerGroupIds: [],
  dealerGroups: []
}

export default {
  name: 'JoinDealerGroupModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    defaultForm: {
      type: Object,
      default() {
        return {}
      }
    },
    groupList: {
      type: Array,
      default() {
        return []
      }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: deepClone(form),
      rules: {
        dealerGroupIds: [
          { type: 'array', required: true, message: '请至少选择一个分组', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    defaultForm: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.form = {
          ...this.form,
          ...deepClone(newVal)
        }
      }
    }
  },
  methods: {
    handleDealerGroupChange(val) {
      const dealerGroups = []
      this.form.dealerGroupIds.map(id => {
        const index = this.groupList.findIndex(item => item.groupId === id)

        if (index > -1) {
          const group = this.groupList[index]

          dealerGroups.push({
            groupId: group.groupId,
            groupType: group.isCommon === 1 ? 1 : 2
          })
        }
      })
      this.form.dealerGroups = dealerGroups
    },
    handleAddGroup() {
      this.$emit('add-group')
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', deepClone(this.form))
        } else {
          return false
        }
      }) 
    },
    handleCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
      this.resetForm()
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.form = deepClone(form)
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-0 {
  margin-bottom: 0;
}

.ant-form-item {
  display: flex;
}

.group-wrap {
  display: flex;
  align-items: flex-start;
  padding: 10px 0 0;

  .ant-checkbox-wrapper {
    margin-right: 8px;
    line-height: 20px;
  }

  & > div {
    word-break: break-all;
    word-wrap: break-word;
  }
}

.btn-wrap {
  display: flex;
  align-items: center;

  &__left {
    flex: 1;
    margin-right: 8px;
    text-align: left;
  }

  .btn-link {
    padding: 0;
  }
}
</style>
