<template>
  <div class="data-table" ref="dataTable">
    <a-row class="action-bar" ref="actionBar" type="flex">
      <a-col class="action-bar-left" flex="1 0 auto">
        <a-button
            type="primary"
            :disabled="selectedRecordIds.length === 0"
            @click="handleJoinGroup"
        >
          加入分组
          <span v-if="selectedRecordIds.length > 0">
            ({{ selectedRecordIds.length }})
          </span>
        </a-button>
      </a-col>
      <a-col class="action-bar-right" flex="1 0 auto">
        <a-space>
          <a-button
              type="primary"
              :loading="exportLoading"
              @click="handleExport"
          >
            导出
          </a-button>
          <a-button
              type="primary"
              @click="onShowDrawer"
          >
            根据标签筛选经销商
          </a-button>
        </a-space>
      </a-col>
    </a-row>

    <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-key="(record, index) => record.id"
        :row-selection="{
        columnWidth: 60,
        selectedRowKeys: selectedRecordIds,
        onSelect: onSelect,
        onSelectAll: onSelectAll
      }"
        :pagination="false"
        :scroll="scroll"
    >
      <div slot="areaInfo" slot-scope="text, record">
        <div>大区：{{ record.regionNames || "-" }}</div>
        <div>城市：{{ record.cityName || "-" }}</div>
      </div>

      <div slot="authorCount" slot-scope="text, record">
        <router-link
            :to="{ name: 'authorList', query: { dealerName: record.name } }"
            target="_blank"
        >
          {{ record.authorCount | humanReadable }}
        </router-link>
      </div>

      <div slot="publicGroupCount" slot-scope="text, record">
        <router-link
            :to="{ name: 'dealerGroupList', query: { dealerName: record.name } }"
            target="_blank"
        >
          {{ record.publicGroupCount | humanReadable }}
        </router-link>
      </div>

      <div class="operate" slot="operate" slot-scope="text, record">
        <router-link
            :to="{ name: 'dealerDetail', query: { dealer_id: record.id,dealer_name: record.name, } }"
            target="_blank"
        >
          查看详情
        </router-link>

        <a-divider type="vertical"/>
        <a @click="handleOperate('joinGroup', record)">加入分组</a>
      </div>
    </a-table>

    <BasePagination
        ref="basePagination"
        :currentPage="pagination.page"
        :pageSize="pagination.size"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
import resizeMixin from "@/mixins/dataTableMixin";

const columns = [
  {
    title: "经销商名称",
    dataIndex: "name",
    width: 200,
    customRender: (text, record, index) => {
      return record.name || "-";
    }
  },
  {
    title: "编码",
    dataIndex: "storeCode",
    width: 150,
    customRender: (text, record, index) => {
      return record.storeCode || "-";
    }
  },
  {
    title: "公司名称",
    dataIndex: "companyName",
    width: 250,
    customRender: (text, record, index) => {
      return record.companyName || "-";
    }
  },
  {
    title: "主体",
    dataIndex: "principalNames",
    width: 200,
    customRender: (text, record, index) => {
      return record.principalNames || "-";
    }
  },
  {
    title: "地区",
    key: "areaInfo",
    width: 200,
    scopedSlots: {customRender: "areaInfo"}
  },
  {
    title: "播主号",
    key: "authorCount",
    width: 100,
    scopedSlots: {customRender: "authorCount"}
  },
  {
    title: "公共分组",
    key: "publicGroupCount",
    width: 100,
    scopedSlots: {customRender: "publicGroupCount"}
  },
  {
    title: "收录时间",
    dataIndex: "ctime",
    width: 162,
    customRender: (text, record, index) => {
      return record.ctime || "-";
    }
  },
  {
    title: "操作",
    key: "operate",
    width: 165,
    fixed: "right",
    scopedSlots: {customRender: "operate"}
  }
];

export default {
  name: "DataTable",
  mixins: [resizeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0
        };
      }
    },
    exportLoading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      columns,
      selectedRecordIds: [],
      scroll: {},
      drawerVisible: false
    };
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.setTableScroll();
      });
    }
  },
  methods: {
    handlePaginationChange(page, size) {
      this.$emit("pagination-change", page, size);
    },
    handleOperate(type, record) {
      this.$emit("operate", type, record);
    },
    onSelect(record, selected, selectedRows, nativeEvent) {
      // console.log(record, selected, selectedRows, nativeEvent)

      const index = this.selectedRecordIds.findIndex(id => id === record.id);

      if(selected && index === -1) { // 选择时
        this.selectedRecordIds.push(record.id);
      }

      if(!selected && index > -1) { // 取消选择时
        this.selectedRecordIds.splice(index, 1);
      }
    },
    onSelectAll(selected, selectedRows, changeRows) {
      // console.log(selected, selectedRows, changeRows)

      if(selected) {
        selectedRows.forEach(row => {
          const index = this.selectedRecordIds.findIndex(id => id === row.id);

          index === -1 && this.selectedRecordIds.push(row.id);
        });
      } else {
        changeRows.forEach(row => {
          const index = this.selectedRecordIds.findIndex(id => id === row.id);

          index > -1 && this.selectedRecordIds.splice(index, 1);
        });
      }
    },
    handleJoinGroup() {
      this.$emit("join-group", [...this.selectedRecordIds]);
    },
    resetSelectedRecord() {
      this.selectedRecordIds = [];
    },
    handleExport() {
      this.$emit("export");
    },
    setTableScroll() {
      const winHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
      const width = this.$refs.dataTable.offsetWidth;
      const height = this.$refs.dataTable.offsetHeight;
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0);
      const scroll = {};

      if(width - 48 < columnsWidth) {
        scroll.x = columnsWidth;
      }

      if(height + 142 > winHeight) {
        const actionBarHeight = this.$refs.actionBar ? this.$refs.actionBar.$el.offsetHeight : 0;
        const tableHeadHeight = document.querySelector(".ant-table-thead").offsetHeight;
        const otherHeight = actionBarHeight + tableHeadHeight + 190;

        scroll.y = `calc(100vh - ${ otherHeight }px)`;
      }

      this.scroll = scroll;
    },
    onShowDrawer() {
      this.$emit("onShowDrawer", true);
    },
  },
  mounted() {
    this.setTableScroll();
    this.$_resizeHandler = () => {
      this.setTableScroll();
    };
  }
};
</script>

<style lang="scss" scoped>
.data-table {
  padding: 24px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .action-bar {
    padding: 0 0 16px;
  }

  .action-bar-right {
    text-align: right;
  }
}
</style>
