<template>
  <section class="page">
    <SearchFilter
        class="mb-16"
        :form="searchForm"
        :fetch-principal="fetchPrincipal"
        :principal-list="principalList"
        :fetch-region="fetchRegion"
        :region-list="regionList"
        :fetch-group="fetchGroup"
        :group-list="groupList"
        :loading="loading"
        @search="handleSearch"
    />

    <DataTable
        ref="dataTable"
        :loading="loading"
        :data="dealerList"
        :pagination="pagination"
        @pagination-change="handlePaginationChange"
        @operate="handleOperate"
        @join-group="handleJoinGroup"
        :export-loading="exportLoading"
        @export="handleExport"
        @onShowDrawer="onShowDrawer"
    />

    <JoinDealerGroupModal
        v-model="joinDealerGroupVisible"
        :default-form="joinDealerGroupForm"
        :group-list="joinDealerGroupList"
        :confirm-loading="joinDealerGroupLoading"
        @add-group="addDealerGroupVisible = true"
        @confirm="handleJoinDealerGroupConfirm"
    />

    <AddDealerGroupModal
        ref="addDealerGroupModal"
        v-model="addDealerGroupVisible"
        :form="addDealerGroupForm"
        :ok-loading="addDealerGroupLoading"
        @staff-select="handleAddDealerGroupStaffSelect"
        @ok="handleAddDealerGroupOk"
    />

    <BaseOrgModal
        v-model="baseOrgVisible"
        :checked-data="baseOrgCheckedData"
        :multiple="baseOrgMultiple"
        @confirm="handleBaseOrgConfirm"
    />
    <SelectDealerDrawer
        ref="SelectDealerDrawer"
        :drawerVisible="drawerVisible"
        @onCloseDrawer="onCloseDrawer"
        @tagSearchResult="tagSearchResult"
    />
  </section>
</template>

<script>
import SearchFilter from "./components/SearchFilter";
import DataTable from "./components/DataTable";
import JoinDealerGroupModal from "@/components/JoinDealerGroupModal";
import AddDealerGroupModal from "@/components/AddDealerGroupModal";
import BaseOrgModal from "@/components/BaseOrgModal";
import baseApi from "@/api/base";
import dealerApi from "@/api/dealer";
import SelectDealerDrawer from "@/components/DealerDrawer/SelectDealerDrawer";

export default {
  name: "DealerList",
  components: {
    SearchFilter,
    DataTable,
    JoinDealerGroupModal,
    AddDealerGroupModal,
    BaseOrgModal,
    SelectDealerDrawer
  },
  data() {
    return {
      searchForm: {
        principalId: "",
        regionId: "",
        nameOrCompanyName: "",
        storeCode: "",
        publicGroupId: "",
        ctime: []
      },
      principalList: [{label: "全部", value: ""}],
      regionList: [{label: "全部", value: ""}],
      groupList: [{label: "全部", value: ""}],
      loading: false,
      dealerList: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0
      },
      joinDealerGroupVisible: false,
      joinDealerGroupType: "",
      joinDealerGroupForm: {
        dealerIds: [],
        dealerGroupIds: [],
        dealerGroups: []
      },
      joinDealerGroupList: [],
      joinDealerGroupLoading: false,
      addDealerGroupVisible: false,
      addDealerGroupForm: {
        groupType: 1,
        groupName: "",
        leaders: [],
        coordinators: []
      },
      addDealerGroupLoading: false,
      addAuthorGroupVisible: false,
      addAuthorGroupForm: {
        type: 1,
        name: ""
      },
      addAuthorGroupLoading: false,
      baseOrgVisible: false,
      baseOrgCheckedData: [],
      baseOrgMultiple: true,
      exportLoading: false,
      drawerVisible: false
    };
  },
  mounted() {
    const {groupId, groupName} = this.$route.query;

    if(groupId && groupName) {
      this.searchForm.publicGroupId = groupId;
      this.fetchGroup(groupName);
    }

    this.fetchDealerList();
  },
  methods: {
    // 获取主体列表
    async fetchPrincipal() {
      try {
        const {code, data, message} = await baseApi.fetchPrincipalList();

        if(code === 200) {
          this.principalList = [
            {label: "全部", value: ""},
            ...data.map(item => {
              return {label: item.principal_name, value: item.id};
            })
          ];
        } else {
          this.$message.error(message);
          this.principalList = [];
        }
      } catch(e) {
        console.error(e);
        this.principalList = [];
      }
    },
    // 获取大区列表
    async fetchRegion(principalId) {
      if(!principalId) {
        this.regionList = [{label: "全部", value: ""}];
        return false;
      }

      try {
        const params = {principalId};
        const {code, data, message} = await baseApi.fetchRegionList(params);

        if(code === 200) {
          this.regionList = [
            {label: "全部", value: ""},
            ...data.map(item => {
              return {label: item.region_names, value: item.region_id};
            })
          ];
        } else {
          this.$message.error(message);
          this.regionList = [];
        }
      } catch(e) {
        console.error(e);
        this.regionList = [];
      }
    },
    // 获取公共分组列表
    async fetchGroup(searchText) {
      if(!searchText) {
        this.groupList = [{label: "全部", value: ""}];
        return false;
      }

      try {
        const params = {
          name: searchText,
          pageNum: 1,
          limit: 50
        };
        const {code, data, msg, errmsg} = await dealerApi.fetchDealerPublicGroupList(params);

        if(code === 0) {
          this.groupList = (data.data || []).map(item => {
            return {label: item.groupName, value: item.groupId};
          });
        } else {
          this.$message.error(msg || errmsg);
        }
      } catch(e) {
        console.error(e);
      }
    },
    handleSearch() {
      this.$refs.dataTable.resetSelectedRecord();
      this.pagination.page = 1;
      this.fetchDealerList();
    },
    handlePaginationChange(page, size) {
      this.pagination.page = page;
      this.pagination.size = size;
      this.fetchDealerList();
    },
    // 获取经销商列表
    async fetchDealerList() {
      this.loading = true;
      try {
        const {ctime, ...restSearchForm} = this.searchForm;
        const {page, size} = this.pagination;
        const params = {
          ...restSearchForm,
          ctimeFrom: ctime.length > 0 ? `${ ctime[0] } 00:00:00` : "",
          ctimeTo: ctime.length > 0 ? `${ ctime[1] } 23:59:59` : "",
          pageNo: page,
          limit: size
        };
        let {code, data, msg, errmsg} = await dealerApi.fetchDealerList(params);

        this.loading = false;

        if(code === 0) {
          this.dealerList = data?.data || [];
          this.pagination.total = data?.total || 0;
        } else {
          this.$message.error(msg || errmsg);
        }
      } catch(e) {
        console.error(e);
        this.loading = false;
      }
    },
    // 处理导出
    async handleExport() {
      this.exportLoading = true;
      try {
        const {ctime, ...restSearchForm} = this.searchForm;
        const params = {
          ...restSearchForm,
          ctimeFrom: ctime.length > 0 ? `${ ctime[0] } 00:00:00` : "",
          ctimeTo: ctime.length > 0 ? `${ ctime[1] } 23:59:59` : ""
        };
        await dealerApi.exportDealerList(params);
        this.exportLoading = false;
      } catch(e) {
        console.error(e);
        this.exportLoading = false;
      }
    },
    handleOperate(type, record) {
      type === "joinGroup" && this.openJoinDealerGroupModal("single", [record.id]);
    },
    handleJoinGroup(recordIds) {
      this.openJoinDealerGroupModal("multiple", [...recordIds]);
    },
    openJoinDealerGroupModal(type, dealerIds) {
      this.joinDealerGroupVisible = true;
      this.joinDealerGroupType = type;
      this.joinDealerGroupForm.dealerIds = dealerIds;
      this.fetchMyDealerGroupList();
    },
    // 获取我创建的经销商分组列表
    async fetchMyDealerGroupList() {
      try {
        const {code, data, message} = await dealerApi.fetchMyDealerGroupList();

        if(code === 200) {
          this.joinDealerGroupList = (data || []).filter(item => item.userType === 1);
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.error(e);
      }
    },
    // 处理加入经销商分组确定
    async handleJoinDealerGroupConfirm(form) {
      this.joinDealerGroupLoading = true;
      try {
        const {dealerIds, dealerGroups} = form;
        const params = {dealerIds, dealerGroups};
        const {code, msg, errmsg} = await dealerApi.joinDealerGroup(params);

        this.joinDealerGroupLoading = false;

        if(code === 0) {
          this.joinDealerGroupType === "single" && this.$message.success("已将该经销商加入分组！");

          if(this.joinDealerGroupType === "multiple") {
            this.$message.success(`已将${ dealerIds.length }个经销商加入分组！`);
            this.$refs.dataTable.resetSelectedRecord();
          }

          this.joinDealerGroupVisible = false;
          this.fetchDealerList();
        } else {
          this.$message.error(msg || errmsg);
        }
      } catch(e) {
        console.error(e);
        this.joinDealerGroupLoading = false;
      }
    },
    handleAddDealerGroupStaffSelect(type) {
      this.staffSelectType = type;
      this.baseOrgVisible = true;

      if(this.staffSelectType === "leaders") {
        const {leaders} = this.addDealerGroupForm;
        this.baseOrgCheckedData = leaders.length > 0 ? [leaders[0].id] : [];
        this.baseOrgMultiple = false;
      }

      if(this.staffSelectType === "coordinators") {
        const {coordinators} = this.addDealerGroupForm;
        this.baseOrgCheckedData = coordinators.length > 0 ? coordinators.map(item => item.id) : [];
        this.baseOrgMultiple = true;
      }
    },
    handleBaseOrgConfirm(val) {
      if(this.staffSelectType === "leaders") {
        this.addDealerGroupForm.leaders = val.checkedUser.map(item => {
          return {id: item.key, name: item.title};
        });
      }

      if(this.staffSelectType === "coordinators") {
        this.addDealerGroupForm.coordinators = val.checkedUser.map(item => {
          return {id: item.key, name: item.title};
        });
      }
    },
    // 处理新建经销商分组确定
    async handleAddDealerGroupOk() {
      this.addDealerGroupLoading = true;
      try {
        const {groupType, groupName, leaders, coordinators} = this.addDealerGroupForm;
        const params = {
          groupType,
          groupName
        };

        if(groupType === 1) {
          params.leaderId = leaders[0].id;
          params.coordinatorIds = coordinators.map(item => item.id);
        }

        const {code, msg, errmsg} = await dealerApi.addDealerGroup(params);

        if(code === 0) {
          this.$message.success("新建经销商分组成功");
          this.addDealerGroupLoading = false;
          this.addDealerGroupVisible = false;
          this.$refs.addDealerGroupModal.resetForm();
          this.fetchMyDealerGroupList();
        } else {
          this.$message.error(msg || errmsg);
          this.addDealerGroupLoading = false;
        }
      } catch(e) {
        console.error(e);
        this.addDealerGroupLoading = false;
      }
    },
    onShowDrawer(data) {
      this.drawerVisible = data;
      this.$refs.SelectDealerDrawer.getSiftDisplay();
    },
    onCloseDrawer(data) {
      this.drawerVisible = data;
    },
    tagSearchResult(data) {
      let routeUrl = this.$router.resolve({
        path: `/dealerManage/dealerTagList`,
        query: {
          tagsData: JSON.stringify(data),
        }
      });

      window.open(routeUrl.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.mb-16 {
  margin-bottom: 16px;
}

.page {
  padding: 0 !important;
  background: #f0f2f5 !important;
}
</style>
